import React, { useEffect, useRef } from 'react';


const StripeBuyBtn = ({ buyButtonId, publishableKey }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (window.StripeBuyButton) {
      window.StripeBuyButton.mount(ref.current);
    }
  }, []);

  return (
    <div className="flex justify-center items-center h-screen"> 
    <stripe-buy-button
      ref={ref}
      buy-button-id={buyButtonId}
      publishable-key={publishableKey}
    >
    </stripe-buy-button>
    </div>
  );
};

export default StripeBuyBtn;
