import React, { useRef, useEffect } from 'react';
import StripeBuyBtn from './views/StripeBuyBtn';
import backgroundImage from './views/Background_AI_Gen.png';
import logoImage from './views/Swell_Watcher_Logo.png'

const App = () => {
  const ref = useRef(null);
  const buyButtonId = process.env.REACT_APP_STRIPE_BUY_BUTTON_ID;
  const publishableKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;

  useEffect(() => {
    if (ref.current) {
      // Initialize or configure the button as necessary
    }
  }, []);

  return (
    <div className="flex flex-col h-screen bg-cover bg-center bg-no-repeat overflow-hidden" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="container mx-auto p-4 flex flex-col justify-between h-full">
        <header className="text-center my-4">
        <div className="flex justify-center my-4">
        <div 
  style={{ 
    width: '200px', 
    height: '200px', 
    backgroundColor: '#000000',
    backgroundSize: 'contain',  // Ensures the image fits within the div
    backgroundRepeat: 'no-repeat',  // Prevents the image from repeating
    backgroundPosition: 'center',  // Centers the image within the div
    backgroundImage: `url(${logoImage})`
  }} 
/></div>
          <h2 className="text-2xl my-2 font-bold">We're Here to Bear Any of Your Storms,</h2>
          <p className='font-bold'>COMING SOON</p>
          {/* <p className='font-bold'>Problem Solving for Seemingly Impossible Tasks</p> */}
          {/* <p className="text-4xl font-bold">
            Our Site is Still Under Construction
          </p> */}
          <div className="flex justify-center -mt-40" style={{ transform: 'scale(1)' }}>
            <StripeBuyBtn
              buyButtonId={buyButtonId}
              publishableKey={publishableKey}
            />
          </div>
        </header>
        <footer className="text-center pt-4 mb-10">
          <p>&copy; Copyright 2023 Swell Watcher</p>
        </footer>
      </div>
    </div>
  );
};

export default App;
